import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { api } from "~/utils/api";
// import { signOut } from "next-auth/react";
import { useRouter } from "next/router";
import Lottie from "lottie-react";
import AnimationPage from "../../../public/animations/animationPage.json";
import { FaTwitter, FaFacebookF, FaInstagram, FaSpotify, FaLinkedinIn, FaYoutube, FaTiktok } from "react-icons/fa6";
import { MdWeb } from "react-icons/md";
import MySvg from "public/icon/SelectSvg";
import { Profile as ProfileType } from "@bnqt/api/src/context";
import isEqual from "lodash/isEqual";
import { useToast } from "~/components/ui/use-toast";
import { cn } from "~/lib/utils";

//page to see your first name, last name, and phone number
export default function Profile() {
  const router = useRouter();
  const [dietRestrictions, setDietRestrictions] = useState<string[]>([]);
  const [updatedProfileData, setUpdatedProfileData] = useState<ProfileType | null>(null);
  const { data: diets } = api.profile.getAllDiets.useQuery();
  const { data: profileData, isLoading } = api.profile.getProfile.useQuery();
  const updateProfile = api.profile.updateProfileNSocials.useMutation();
  const updateMultiChoiceAttributes = api.profile.updateMultiChoiceAttributes.useMutation();
  const { toast } = useToast();

  //if there is no token, redirect to the login page
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      router.push("/login");
    }
  });

  useEffect(() => {
    if (profileData) {
      setDietRestrictions(profileData?.dietRestrictions.map((d) => titleCase(enumValueToString(d.name))) || []);
      setUpdatedProfileData(profileData);
    }
  }, [profileData]);

  if (isLoading) {
    return (
      <div className="z-20 flex h-full w-screen items-center justify-center bg-white">
        <Lottie animationData={AnimationPage} style={{ width: 100, height: 100 }} className="-mt-24" />
      </div>
    );
  }

  const handleProfileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUpdatedProfileData((prevData) => ({
      ...prevData!,
      [name]: value,
    }));
  };

  // Function to handle save changes
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!updatedProfileData || !profileData) {
      return;
    }
    let updatedData = { ...profileData };

    updatedData = {
      ...updatedData,
      firstName: updatedProfileData["firstName"],
      lastName: updatedProfileData["lastName"],
      bio: updatedProfileData["bio"],
      LinkedIn: updatedProfileData["LinkedIn"],
      Twitter: updatedProfileData["Twitter"],
      Instagram: updatedProfileData["Instagram"],
      YouTube: updatedProfileData["YouTube"],
      TikTok: updatedProfileData["TikTok"],
      Spotify: updatedProfileData["Spotify"],
      Facebook: updatedProfileData["Facebook"],
      website: updatedProfileData["website"],
    };
    //check to see if there are any changes
    if (!isEqual(updatedData, profileData)) {
      console.log("Changes made");
      updateProfile.mutateAsync(updatedData).catch((e) => console.error(e));
      //post the changes to the server
    }

    //check to see if diet restrictions have changed
    if (
      !isEqual(
        dietRestrictions,
        profileData?.dietRestrictions.map((d) => titleCase(enumValueToString(d.name))),
      ) &&
      updatedData
    ) {
      console.log("Diet changes made");
      updateMultiChoiceAttributes
        .mutateAsync({ userId: profileData.id, diets: dietRestrictions })
        .catch((_e) => console.error("Something went wrong updating diet restrictions"));
    }
    toast({
      title: "Profile Updated",
      description: "Your profile has been updated successfully",
    });
    return;
  };

  function handleDietChange(diet: string) {
    if (diet === "None") {
      setDietRestrictions([diet]);
      return;
    }

    // Deselect everything else if 'None' is selected
    if (dietRestrictions.includes("None")) {
      setDietRestrictions([diet]);
      return;
    }

    // Deselect 'None' if any pill is pressed
    if (dietRestrictions.includes(diet)) {
      const updatedDiets = dietRestrictions.filter((d) => d !== diet);
      setDietRestrictions(updatedDiets);
    } else {
      setDietRestrictions([...dietRestrictions, diet]);
    }
  }

  const DietRestrictionsRender = () => (
    <div className="mt-1 flex flex-row flex-wrap">
      <ClickablePills values={diets || []} onChange={handleDietChange} userValues={dietRestrictions} />
    </div>
  );

  return (
    <div className=" flex w-screen flex-col ">
      <div className="flex w-full flex-col self-center px-4 py-3 md:w-[760px]">
        <form onSubmit={(e) => handleSubmit(e)} className="flex flex-col">
          <h1 className="text-bq-950 text-2xl font-bold">Your Profile</h1>
          <p className="text-bq-800 my-1 text-base">Fill out your information for other guests to see.</p>
          <div className="flex flex-col md:mt-2 md:flex-row-reverse md:justify-end">
            <div className="my-4 flex flex-col items-start md:mx-5 md:my-1.5 md:w-80 md:items-center md:px-2">
              <p className="text-bq-950 text-lg font-bold">Profile Picture</p>
              <div className="my-1 flex h-20 w-20 items-center justify-center rounded-full md:my-2 md:self-center">
                <MySvg
                  height="70"
                  width="70"
                  color="white"
                  backgroundColor={profileData?.picColor === "white" ? "#29182B" : profileData?.picColor}
                  iconName={profileData?.pic || "rice"}
                />
              </div>
              <div className="mt-4 flex h-full w-full flex-col self-center md:items-center">
                <p className="text-bq-950 text-base font-bold">Dietary Restrictions</p>
                {DietRestrictionsRender()}
              </div>
            </div>
            <div className="flex flex-col">
              <div className="my-2 flex flex-col">
                <label htmlFor={"firstName"} className="text-bq-950 mb-1 text-base">
                  First Name
                </label>
                <input
                  type="text"
                  id={"firstName"}
                  name={"firstName"}
                  placeholder={`Enter your firstName`}
                  value={updatedProfileData?.firstName}
                  onChange={handleProfileInputChange}
                  required={true}
                  className="focus:border-bq-400 border-bq-300 w-80 rounded-md border p-2 focus:outline-none"
                />
              </div>
              <div className="my-2 flex flex-col">
                <label htmlFor={"lastName"} className="text-bq-950 mb-1 text-base">
                  Last Name
                </label>
                <input
                  type="text"
                  id={"lastName"}
                  name={"lastName"}
                  placeholder={`Enter your lastName`}
                  value={updatedProfileData?.lastName}
                  onChange={handleProfileInputChange}
                  required={true}
                  className="focus:border-bq-400 border-bq-300 w-80 rounded-md border p-2 focus:outline-none"
                />
              </div>
              {/* Bio */}
              <div className="my-2 flex flex-col">
                <label htmlFor={"bio"} className="text-bq-950 mb-1 text-base">
                  Bio
                </label>
                <textarea
                  id={"bio"}
                  name={"bio"}
                  placeholder={`Enter your bio`}
                  value={updatedProfileData?.bio}
                  onChange={handleProfileInputChange}
                  required={false}
                  className="focus:border-bq-400 border-bq-300 w-80 rounded-md border p-2 focus:outline-none"
                />
              </div>
            </div>
          </div>
          {/* social links */}
          <div className="mb-4 mt-3 md:mt-0">
            <h1 className="text-bq-950 mb-2 text-xl font-bold">Social Links</h1>
            <div className="flex flex-col items-start md:h-48 md:flex-row md:flex-wrap md:space-y-0">
              <SocialLink
                service="LinkedIn"
                urlPrefix="linkedin.com/in/"
                icon={<FaLinkedinIn color="#31262c" />}
                username={updatedProfileData?.["LinkedIn"]}
                handleInputChange={handleProfileInputChange}
              />
              <SocialLink
                service="Twitter"
                urlPrefix="twitter.com/"
                icon={<FaTwitter color="#31262c" />}
                username={updatedProfileData?.["Twitter"]}
                handleInputChange={handleProfileInputChange}
              />
              <SocialLink
                service="Instagram"
                urlPrefix="instagram.com/"
                icon={<FaInstagram color="#31262c" />}
                username={updatedProfileData?.["Instagram"]}
                handleInputChange={handleProfileInputChange}
              />
              <SocialLink
                service="YouTube"
                urlPrefix="youtube.com/@"
                icon={<FaYoutube color="#31262c" />}
                username={updatedProfileData?.["YouTube"]}
                handleInputChange={handleProfileInputChange}
              />
              <SocialLink
                service="TikTok"
                urlPrefix="tiktok.com/@"
                icon={<FaTiktok color="#31262c" />}
                username={updatedProfileData?.["TikTok"]}
                handleInputChange={handleProfileInputChange}
              />
              <SocialLink
                service="Spotify"
                urlPrefix="spotify.com/user/"
                icon={<FaSpotify color="#31262c" />}
                username={updatedProfileData?.["Spotify"]}
                handleInputChange={handleProfileInputChange}
              />
              <SocialLink
                service="Facebook"
                urlPrefix="facebook.com/"
                icon={<FaFacebookF color="#31262c" />}
                username={updatedProfileData?.["Facebook"]}
                handleInputChange={handleProfileInputChange}
              />
              <div className="mb-4 flex items-center gap-2">
                <span className="mx-1">
                  <MdWeb size={20} color="#31262c" />
                </span>
                <div className="flex-1">
                  <div className="border-bq-300 bg-bq-50 flex w-72 overflow-hidden rounded-md border">
                    <input
                      type="text"
                      name={"website"}
                      id={"website"}
                      value={updatedProfileData?.["website"]}
                      onChange={(e) => handleProfileInputChange(e)}
                      className="bg-bq-50 focus:border-bq-200 placeholder-bq-500 text-bq-900 w-full flex-1 px-3 py-1 text-base"
                      placeholder="Your Website"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className="bg-bq-600 hover:bg-bq-700 focus:shadow-outline mb-4 w-1/2 self-center rounded-full px-4 py-2 font-bold text-white "
            type="submit"
          >
            Save Changes
          </button>
        </form>
      </div>
    </div>
  );
}

export const SocialLink = ({
  icon,
  service,
  urlPrefix,
  username,
  handleInputChange,
  size,
}: {
  service: string;
  urlPrefix: string;
  icon: JSX.Element;
  username?: string;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  size?: string;
}) => (
  <div className="mb-4 flex items-center gap-2 md:mb-0 md:mr-6">
    <span className="mx-1">{icon}</span>
    <div className="flex-1">
      <div
        className={cn("border-bq-300 bg-bq-50 flex w-72 overflow-hidden rounded-md border", size === "sm" && "w-60")}
      >
        <span className="text-bq-500 border-bq-300 inline-flex items-center border-r px-3 text-sm">{urlPrefix}</span>
        <input
          type="text"
          name={service}
          id={service}
          placeholder="username"
          required={false}
          className="bg-bq-50 focus:border-bq-200 placeholder-bq-500 text-bq-900 w-full flex-1 px-3 py-1 text-base"
          value={username || ""}
          onChange={(e) => handleInputChange(e)}
        />
      </div>
    </div>
  </div>
);

export const ClickablePills = ({
  values,
  onChange,
  userValues,
}: {
  values: string[];
  onChange: (value: string) => void;
  userValues: string[];
}) => (
  <div className="mt-1 flex flex-row flex-wrap justify-start md:justify-center">
    {values.map((value) => {
      const active = userValues.includes(value);
      return (
        <button
          onClick={() => onChange(value)}
          key={value}
          type={"button"}
          className={`${
            active ? "bg-bq-600 text-white" : "bg-bq-50 text-bq-700"
          } focus:shadow-outline border-bq-700 mx-2 my-1 rounded-full border px-4 py-1.5 text-sm focus:outline-none`}
        >
          {value}
        </button>
      );
    })}
  </div>
);

export function enumValueToString(str: string) {
  return str.replaceAll("_hypen_", "-").replaceAll("slash", "/").replaceAll("_", " ");
}

export function titleCase(str: string) {
  return str.toLowerCase().replace(/(^|\s)(\S)/g, (_match: string, group1: string, group2: string) => {
    return group1 + group2.toUpperCase();
  });
}
